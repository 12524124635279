import type { FC } from 'react';
import PropTypes from 'prop-types';
import { format } from 'date-fns';
import User01Icon from '@untitled-ui/icons-react/build/esm/User01';
import Mail04Icon from '@untitled-ui/icons-react/build/esm/Mail04';
import UsersPlus from '@untitled-ui/icons-react/build/esm/UsersPlus';
import MessageChatCircle from '@untitled-ui/icons-react/build/esm/MessageChatCircle';
import AlertCircle from '@untitled-ui/icons-react/build/esm/AlertCircle';
import SearchMd from '@untitled-ui/icons-react/build/esm/SearchMd';
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  Stack,
  SvgIcon,
  Tooltip,
  Typography
} from '@mui/material';
import { Scrollbar } from 'src/components/scrollbar';
import { tokens } from 'src/locales/tokens';
import { useTranslation } from 'react-i18next';
import { INotification } from 'src/types/notification';
import { paths } from 'src/paths';
import { useRouter, Router } from 'src/hooks/use-router';

const renderContent = (notification: INotification, router: Router, onClose: (() => void) | undefined): JSX.Element | null => {  
  switch (notification.data.notificationType.trim()) {
    case 'ONBOARDING_NOTIFICATION': {
      const date = new Date(notification.createdAt);
      const createdAt = format(date, "dd MMM, hh:mm a");

      const onClickCustomer = (id: string | undefined) => {
        if(onClose) {
          onClose()
        }

        if (!id) {
          router.push(paths.dashboard.customers.index)
        } else {
          router.push(paths.dashboard.customers + `/${id}/edit`)
        }
      }

      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              <SvgIcon>
                <UsersPlus />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            onClick={() => onClickCustomer(notification.id)}
            primary={(
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 0.5 }}
                >
                   {notification.data.title}
                </Typography>
                <Typography variant="body2">
                  {notification.data.description}
                </Typography>
              </Box>
            )}
            secondary={(
              <Typography
                color="text.secondary"
                variant="caption"
              >
                {createdAt}
              </Typography>
            )}
            sx={{ my: 0 }}
          />
        </>
      );
    }
    case 'CHAT_NOTIFICATION': {
      const date = new Date(notification.createdAt);
      const createdAt = format(date, "dd MMM, hh:mm a");

      const onClickChat = (id: string | undefined) => {
        if(onClose) {
          onClose()
        }
        
        if (!id) {
          router.push(paths.dashboard.orders.chat)
        } else {
          router.push(paths.dashboard.orders.chat + `/${id}`)
        }
      }

      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              <SvgIcon>
                <MessageChatCircle />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText 
            onClick={() => onClickChat(notification.data.orderId)}
            primary={(
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 0.5 }}
                >
                   {notification.data.title}
                </Typography>
                <Typography variant="body2">
                {notification.data.description}
                </Typography>
              </Box>
            )}
            secondary={(
              <Typography
                color="text.secondary"
                variant="caption"
              >
                {createdAt}
              </Typography>
            )}
            sx={{ my: 0 }}
          />
        </>
      );
    }
    case 'ORDER_NOTIFICATION': {
      const date = new Date(notification.createdAt);
      const createdAt = format(date, "dd MMM, hh:mm a");

      const onClickOrder = (id: string | undefined) => {
       if(onClose) {
          onClose()
        }
        if (!id) {
          router.push(paths.dashboard.orders.chat)
        } else {
          router.push(paths.dashboard.orders.chat + `/${id}`)
        }
      }

      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              <SvgIcon>
                <AlertCircle />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            onClick={() => onClickOrder(notification.id)}
            primary={(
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 0.5 }}
                >
                  {notification.data.title}
                </Typography>
                <Typography variant="body2">
                {notification.data.description}
                </Typography>
              </Box>
            )}
            secondary={(
              <Typography
                color="text.secondary"
                variant="caption"
              >
                {createdAt}
              </Typography>
            )}
            sx={{ my: 0 }}
          />
        </>
      );
    }
    default:
      const date = new Date(notification.createdAt);
      const createdAt = format(date, "dd MMM, hh:mm a");

      return (
        <>
          <ListItemAvatar sx={{ mt: 0.5 }}>
            <Avatar>
              <SvgIcon>
                <SearchMd />
              </SvgIcon>
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={(
              <Box
                sx={{
                  alignItems: 'center',
                  display: 'flex',
                  flexWrap: 'wrap'
                }}
              >
                <Typography
                  variant="subtitle2"
                  sx={{ mr: 0.5 }}
                >
                  {notification.data.title}
                </Typography>
                <Typography variant="body2">
                {notification.data.description}
                </Typography>
              </Box>
            )}
            secondary={(
              <Typography
                color="text.secondary"
                variant="caption"
              >
                {createdAt}
              </Typography>
            )}
            sx={{ my: 0 }}
          />
        </>
      );
  }
};

interface NotificationsPopoverProps {
  anchorEl: null | Element;
  notifications: INotification[];
  onClose?: () => void;
  onMarkAllAsRead?: () => void;
  onRemoveOne?: (id: string) => void;
  open?: boolean;
}

export const NotificationsPopover: FC<NotificationsPopoverProps> = (props) => {
  const { t } = useTranslation();
  const router = useRouter()
  const {
    anchorEl,
    notifications,
    onClose,
    onMarkAllAsRead,
    onRemoveOne,
    open = false,
    ...other
  } = props;

  const isEmpty = notifications.length === 0;

  return (
    <Popover
      anchorEl={anchorEl}
      anchorOrigin={{
        horizontal: 'left',
        vertical: 'bottom'
      }}
      disableScrollLock
      onClose={onClose}
      open={open}
      PaperProps={{ sx: { width: 380 } }}
      {...other}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={2}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography
          color="inherit"
          variant="h6"
        >
          {t(tokens.notification.dashboard.title)}
        </Typography>
        <Tooltip title="Mark all as read">
          <IconButton
            onClick={onMarkAllAsRead}
            size="small"
            color="inherit"
          >
            <SvgIcon>
              <Mail04Icon />
            </SvgIcon>
          </IconButton>
        </Tooltip>
      </Stack>
      {
        isEmpty
          ? (
            <Box sx={{ p: 2 }}>
              <Typography variant="subtitle2">
              {t(tokens.notification.dashboard.not_found)}
              </Typography>
            </Box>
          )
          : (
            <Scrollbar sx={{ maxHeight: 400 }}>
              <List disablePadding>
                {notifications.map((notification) => (
                  <ListItem
                    divider
                    key={`${notification.id}-${notification.data.notificationType}-${notification.createdAt}`} 
                    sx={{
                      alignItems: 'flex-start',
                      '&:hover': {
                        backgroundColor: 'action.hover'
                      },
                      '& .MuiListItemSecondaryAction-root': {
                        top: '24%'
                      }
                    }}
                    /*secondaryAction={(
                      <Tooltip title="Remove">
                        <IconButton
                          edge="end"
                          onClick={() => onRemoveOne?.(notification.id)}
                          size="small"
                        >
                          <SvgIcon>
                            <XIcon />
                          </SvgIcon>
                        </IconButton>
                      </Tooltip>
                    )}*/
                  >
                    {renderContent(notification, router, onClose)}
                  </ListItem>
                ))}
              </List>
            </Scrollbar>
          )
      }
    </Popover>
  );
};

NotificationsPopover.propTypes = {
  anchorEl: PropTypes.any,
  notifications: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onMarkAllAsRead: PropTypes.func,
  onRemoveOne: PropTypes.func,
  open: PropTypes.bool
};
