import { businessPartnerApi } from "src/api/business-partner"

export const tokens = {
  common: {
    languageChanged: "common.languageChanged",
    currency: "common.currency",
    locale: "common.locale",
    currency_code: "common.currency_code",
  },
  language: {
    languageTitle: "languageTitle",
    portuguese: "portuguese",
    english: "english",
    german: "german",
    spanish: "spanish",
  },
  page: {
    order: "title.order",
    user: "title.user",
    orderCreate: "title.order.create",
    chat: "title.chat",
    orderType: "title.orderType",
    orderTypeCreate: "title.orderType.create",
    orderTypeEdit: "title.orderType.edit",
    concierge: "tittle.concierge",
    conciergeCreate: "tittle.concierge.create",
    conciergeEdit: "tittle.concierge.edit",
    condominium: "tittle.condominium",
    condominiumCreate: "tittle.condominium.create",
    condominiumEdit: "tittle.condominium.edit",
    businessPartner: "tittle.businessPartner",
    businessPartnerCreate: "tittle.businessPartner.create",
    businessPartnerEdit: "tittle.businessPartner.edit",
    businessPartnerService: "tittle.businessPartnerService",
    businessPartnerServiceCreate: "tittle.businessPartnerService.create",
    businessPartnerServiceEdit: "tittle.businessPartnerService.edit",
    event: "tittle.event",
    customersEvent: "tittle.customersEvent",
    eventCreate: "tittle.event.create",
    eventEdit: "tittle.event.edit",
  },
  nav: {
    requests: "nav.requests",
    concierge: "nav.concierge",
    condominium: "nav.condominium",
    businessPartner: "nav.businessPartner",
    orderType: "nav.orderType",
    event: "nav.event",
    requestStatus: "nav.requestStatus",
    academy: "nav.academy",
    account: "nav.account",
    analytics: "nav.analytics",
    auth: "nav.auth",
    blog: "nav.blog",
    browse: "nav.browse",
    calendar: "nav.calendar",
    chat: "nav.chat",
    checkout: "nav.checkout",
    concepts: "nav.concepts",
    staff: "nav.staff",
    customerMenu: "nav.customerMenu",
    contact: "nav.contact",
    course: "nav.course",
    create: "nav.create",
    crypto: "nav.crypto",
    customers: "nav.customers",
    users: "nav.users",
    dashboard: "nav.dashboard",
    details: "nav.details",
    ecommerce: "nav.ecommerce",
    edit: "nav.edit",
    error: "nav.error",
    feed: "nav.feed",
    fileManager: "nav.fileManager",
    files: "nav.files",
    finance: "nav.finance",
    fleet: "nav.fleet",
    forgotPassword: "nav.forgotPassword",
    invoiceList: "nav.invoices",
    paymentList: "nav.payments",
    jobList: "nav.jobList",
    kanban: "nav.kanban",
    list: "nav.list",
    login: "nav.login",
    logout: "nav.logout",
    billing: "nav.billing",
    settings: "nav.settings",
    logistics: "nav.logistics",
    mail: "nav.mail",
    management: "nav.management",
    orderList: "nav.orders",
    overview: "nav.overview",
    pages: "nav.pages",
    postCreate: "nav.postCreate",
    postDetails: "nav.postDetails",
    postList: "nav.postList",
    pricing: "nav.pricing",
    productList: "nav.products",
    profile: "nav.profile",
    register: "nav.register",
    resetPassword: "nav.resetPassword",
    socialMedia: "nav.socialMedia",
    verifyCode: "nav.verifyCode",
  },
  commonScreen: {
    sortBy: "sort.by",
    FilterBy: "filter.by",
    searchBy: "search.by",
    rollback_title: "common.rollback_title",
    createForm:{
      brand: "createForm.brand"
    },
    filters: {
      searchByDocumentNumber: "search.by.documentNumber",
      searchByOrderType: "search.by.orderType",
      searchByName: "search.by.name",
      searchByRegistrationNumber: "search.by.registrationNumber",
      searchByEmail: "search.by.email",
      FilterByOthers: "FilterByOthers",
      ByDataonScreen: "ByDataonScreen",
      sortByCreatedNew: "sortByCreatedNew",
      sortByCreatedLast: "sortByCreatedLast",
    },
    table: {
      header: {
        actions: "header.actions",
        brand: "header.brand"
      },
      footer: {
        rowsPerPage: "rows.per.page",
      },
    },
    actions: {
      delete: "delete",
      edit: "edit",
    },
    maleGender: "male.gender",
    femaleGender: "female.gender",
    othersGender: "others.gender",
    socialSecuityNumber: "socialSecuityNumber",
    idNumber: "idNumber",
    driversLicence: "driversLicence",
    documentNumber: "documentNumber",
    yes: "yes",
    no: "no",
    active: "active",
    disabled: "disabled",
    requiredFiled: "requiredFiled",
    marital_status: {
      single: "common.marital_status.single",
      married: "common.marital_status.married",
      widower: "common.marital_status.widower",
      divorced: "common.marital_status.divorced",
    },
    validation: {
      invalid_field: "common.validation.invalid_field",
      required_field: "common.validation.required_field",
    },
    btn_cancel: "common.cancel",
  },
  profileScreen: {
    main_title_profile: "main_title_profile",
    btn_create_profile: "btn_create_profile",
    table_main: {
      header: {
        picture: "picture",
        email: "email",
        usertype: "usertype",
        status: "status",
        lastLogin: "lastLogin",
        createAt: "createAt",
      },
      filters: {
        name: "name",
        empty_state: {
          filter_usertype: "filter_usertype",
          text_field: "text_field",
        },
        status: "status",
        sort_by_date_new: "sort_by_date_new",
        sort_by_date_last: "sort_by_date_last",
        sort_by_user_active: "sort_by_user_active",
        sort_by_usertype: "sort_by_usertype",
      },
    },
    form: {
      general: {
        title_label_picture: "title_label_picture",
        title_label_full_name: "title_label_full_name",
        title_label_email: "title_label_email",
        title_label_email_name: "title_label_email_name",
        btn_edit_name_label: "btn_edit_name_label",
        title_label_profile: "title_label_profile",
        title_label_message: "title_label_message",
      },
      notifications: {
        email_label_title: "email_label_title",
        email_label_title_security: "email_label_title_security",
        phone_label_title_security: "phone_label_title_security",
        phone_label_text_security: "phone_label_text_security",
      },
      security: {
        password_label_title: "password_label_title",
        password_label_title_security: "password_label_title_security",
        phone_label_title_security: "phone_label_title_security",
        phone_label_text_security: "phone_label_text_security",
      },
      multifactorAuthentication: {
        Authenticator_label_title: "Authenticator_label_title",
        Authenticator_label_title_security: "Authenticator_label_title_security",
        text_label_title_security: "text_label_title_security",
        text_label_text_security: "text_label_text_security",
      },
    },
  },
  userScreen: {
    main_title_user: "main_title_user",
    btn_create_user: "btn_create_user",
    table_main: {
      header: {
        name: "name",
        email: "email",
        usertype: "usertype",
        status: "status",
        lastLogin: "lastLogin",
        createAt: "createAt",
      },
      filters: {
        name: "name",
        search_user: "search_user",
        filter_by_none: "filter_by_none",
        filter_by_firstName: "filter_by_firstName",
        filter_by_lastName: "filter_by_lastName",
        filter_by_phoneNumber: "filter_by_phoneNumber",
        filter_by_customer_email: "filter_by_customer_email",
        empty_state: {
          filter_usertype: "filter_usertype",
          text_field: "text_field",
        },
        status: "status",
        sort_by_date_new: "sort_by_date_new",
        sort_by_date_last: "sort_by_date_last",
        sort_by_user_active: "sort_by_user_active",
        sort_by_usertype: "sort_by_usertype",
        sort_by_usertype_asc: "sort_by_usertype_asc",
        sort_by_usertype_desc: "sort_by_usertype_desc",
      },
    },

    form: {
      title_label_detail: "title_label",
      user_title_label_edit: "user_title_label_edit",
      user_title_label_create: "user_title_label_create",
      btn_edit_label: "btn_edit_label",
      user_id_label: "user_id_label",
      request_number_label: "request_number_label",
      user_label: "user_label",
      user_name_label: "user_name_label",
      user_type_label: "user_type_label",
      user_gender_label: "user_gender_label",
      user_phone_label: "user_phone_label",
      user_welcomeNotification_label: "user_welcomeNotification_label",
      user_status_obs_label: "user_status_obs_label",
      user_password_label: "user_password_label",
      user_email_label: "user_email_label",
      user_document_label: "user_document_label",
      user_status_label: "user_status_label",
      user_last_login_label: "user_last_login_label",
      total_amount_label: "total_amount_label",
      request_type_label: "request_type_label",
      request_status_label: "request_status_label",
      btn_approve: "btn_approve",
      btn_reject: "btn_reject",
      btn_close: "btn_close",
      btn_chat: "btn_chat",
      btn_save: "btn_save",
      btn_cancel: "btn_cancel",

      createForm: {
        create_title: "create_title",
        btn_create_order: "btn_create_order",
        sucess_message: "sucess_message",
        error_message: "error_message",
      },
    },
  },
  requestScreen: {
    main_title: "main_title",
    btn_create_request: "btn_create_request",
    table_main: {
      header: {
        number: "number",
        status: "status",
        paymentStatus: "paymentStatus",
        amount: "amount",
        client: "client",
        concierge: "concierge",
        createAt: "createAt",
      },
      filters: {
        order: "order",
        empty_state: {
          filter_category: "filter_category",
          text_field: "text_field",
          orderId_filter: "orderId_filter",
        },
        status: "status",
        sort_by_date_new: "sort_by_date_new",
        sort_by_date_last: "sort_by_date_last",
        sort_by_total_amount_min: "sort_by_total_amount_min",
        sort_by_total_amount_max: "sort_by_total_amount_max",
      },
    },
    form: {
      title_label_detail: "title_label",
      title_label_edit: "title_label_edit",
      btn_edit_label: "btn_edit_label",
      id_label: "id_label",
      request_number_label: "request_number_label",
      customer_label: "customer_label",
      customer_name_label: "customer_name_label",
      customer_phone_label: "customer_phone_label",
      customer_email_label: "customer_email_label",
      customer_document_label: "customer_document_label",
      request_date_label: "request_date_label",
      total_amount_label: "total_amount_label",
      request_type_label: "request_type_label",
      request_businessPartner_label: "request_businessPartner_label",
      request_status_label: "request_status_label",
      request_payment_status_label: "request_payment_status_label",
      concierge_label: "concierge_label",
      businessPartner_label: "businessPartner_label",
      businessPartnerService_label: "businessPartnerService_label",
      order_notes_label: "order_notes_label",
      order_placeholder_label: "order_placeholder_label",
      concierge_identification_label: "concierge_identification_label",
      concierge_name_label: "concierge_name_label",
      concierge_phone_label: "concierge_phone_label",
      concierge_email_label: "concierge_email_label",
      businessPartner_name_label: "businessPartner_name_label",
      businessPartnerService_name_label: "businessPartnerService_name_label",
      businessPartner_phone_label: "businessPartner_phone_label",
      businessPartner_email_label: "businessPartner_email_label",
      closing_date_label: "closing_date_label",
      payment_label: "payment_label",
      payment_date_label: "payment_date_label",
      payment_type_label: "payment_type_label",
      btn_approve: "btn_approve",
      btn_reject: "btn_reject",
      btn_close: "btn_close",
      btn_chat: "btn_chat",
      btn_save: "btn_save",
      btn_cancel: "btn_cancel",
      default_grouped_condominium_name: "default_grouped_condominium_name",
      customer_default_select_option: "customer_default_select_option",
      condominium_label: "order.form.condominium_label",
      is_recurring_label: "order.form.is_recurring_label",
      is_recurring_description_label: "order.form.is_recurring_description_label",
      createForm: {
        create_title: "create_title",
        btn_create_order: "btn_create_order",
        sucess_message: "sucess_message",
        error_message: "error_message",
      },
      updateFrom: {
        sucess_message: "updateFrom.sucess_message",
        error_message: "updateFrom.error_message",
      },
      chat: {
        btn_see_order: "btn_see_order",
        last_active: "last_active",
        send_message: "send_message",
        me: "me",
      },
    },
  },
  orderTypeScreen: {
    main_title: "main.title.orderType",
    btn_create_orderType: "btn_create_orderType",
    btn_update_orderType: "btn_update_orderType",
    table_main: {
      header: {
        id: "orderType.id",
        orderType: "orderType.orderType",
        createdAt: "orderType.createdAt",
        isInactive: "orderType.isInactive",
      },
      filters: {
        sort_by_order_type_asc: "sort_by_order_type_asc",
        sort_by_order_type_desc: "sort_by_order_type_desc",
        isInactive: "isInactiveOrderTypeLabel",
      },
    },
    form: {
      createForm: {
        create_title: "orderType_create_title",
        orderType: "orderType.orderType",
        orderTypeDetails: "orderType.details",
        shortDescription: "orderType.shortDescription",
        fullDescription: "orderType.fullDescription",
        isInactiveLabel: "orderType.isInactiveLabel",
        btnCreateOrderType: "btn.orderType",
        btnCancelOrderType: "btn.cancel.orderType",
        orderTypeBreadcrumbsNavigationList: "BreadcrumbsNavigationList",
        orderTypeBreadcrumbsNavigationEdit: "BreadcrumbsNavigationEdit",
      },
      editForm: {
        edit_title: "orderType_edit_title",
        btnEditOrderType: "btn.editOrderType",
      },
      sucess_message: "orderType.sucess_message",
      error_message: "orderType.error_message",
    },
  },
  customerScreen: {
    main_title: "main.title.customerType",
    dashboard_customer_details: "dashboard_customer_details",
    last_customer_details: "last_customer_details",
    customer_created_at: "customer_created_at",
    import_label: "customer_import_label",
    export_label: "customer_export_label",
    customer_id: "customer_id",
    btn_create_customer: "btn_create_customer",
    btn_update_customer: "btn_update_customer",
    email: {
      btn_send_mail: "customerScreen.email.btn_send_mail",
      email_title: "customerScreen.email.email_title",
      options: {
        resend_last_invoice: "customerScreen.email.resend_last_invoice",
        send_password_reset: "customerScreen.email.send_password_reset",
        send_verification: "customerScreen.email.send_verification",
      },
    },
    data_management: {
      title: "customerScreen.data_management.title",
      btn_delete_account: "customerScreen.data_management.btn_delete_account",
      description: "customerScreen.data_management.description",
    },
    status_active: "customerScreen.status_active",
    status_enabled: "customerScreen.status_enabled",
    table_main: {
      header: {
        id: "customer.id",
        customer: "customer.customer",
        createdAt: "customer.createdAt",
        name: "customer.name",
        location: "customer.location",
        status: "customer.status",
        orders: "customer.orders",
        spent: "customer.spent",
        actions: "customer.spent",
      },
      filters: {
        all: "customer.all",
        pending_approval: "customer.pending_approval",
        accepts_markenting: "customer.accepts_markenting",
        search: "search",
        sort_by_created_new: "sort_by_created_new",
        sort_by_created_last: "sort_by_created_last",
        sort_by_order_type_asc: "sort_by_order_type_asc",
        sort_by_order_type_desc: "sort_by_order_type_desc",
        sort_by_update_new: "sort_by_update_new",
        sort_by_update_last: "sort_by_update_last",
        sort_by_total_asc: "sort_by_total_asc",
        sort_by_total_desc: "sort_by_total_desc",
        filter_by_none: "filter_by_none",
        filter_by_firstName: "filter_by_firstName",
        filter_by_lastName: "filter_by_lastName",
        filter_by_condominiumName: "filter_by_condominiumName",
        filter_by_documentNumber: "filter_by_documentNumber",
        filter_by_customer_email: "filter_by_customer_email",
      },
    },
    form: {
      first_name: "customer.first_name",
      last_name: "customer.last_name",
      condominum_name: "customer.condominum_name",
      country_name: "customer.country_name",
      state_name: "customer.state_name",
      email_name: "customer.email_name",
      document_name: "customer.document_name",
      city_name: "customer.city_name",
      birthday_name: "customer.birthday_name",
      phone_name: "customer.phone_name",
      marital_status_name: "customer.marital_status_name",
      gender_name: "customer.gender_name",
      customer_notes: "customer.customer_notes",
      customer_notes_description: "customer.customer_notes_description",
      created_date: "customer.created_date",
      updated_date: "customer.updated_date",
      customer_receive_contacts: "customer.customer_receive_contacts",
      customer_receive_contacts_description: "customer.customer_receive_contacts_description",
      customer_active: "customer.customer_active",
      customer_active_description: "customer.customer_active_description",
      customer_not_register: "customer.customer_not_register",
      customer_not_register_description: "customer.customer_not_register_description",
      btn_update_customer: "customer.btn_update_customer",
      btn_customer_cancel: "customer.btn_customer_cancel",
      edit_customer: "customer.edit_customer",
      createForm: {
        create_title: "customer_create_title",
        customer: "customer.customer",
        customerDetails: "customer.details",
        shortDescription: "customer.shortDescription",
        fullDescription: "customer.fullDescription",
        btnCreateCustomer: "btn.customer",
        btnCancelCustomer: "btn.cancel.Customer",
      },
      editForm: {
        title: "customer.form.edit.title",
        btnEditCustomer: "btn.editCustomer",
      },
      sucess_message: "Customer.sucess_message",
      error_message: "Customer.error_message",
    },
    details: {
      detail_title: "detail_title",
      customer_detail_column: "customer_detail_column",
      customer_address_column: "customer_address_column",
      customer_contacts_column: "customer_contacts_column",
      customer_invoice_column: "customer_invoice_column",
      email_label: "email_label",
      phone_label: "phone_label",
      country_label: "country_label",
      state_label: "state_label",
      address_label_1: "address_label_1",
      address_label_2: "address_label_2",
      condominium_label: "condominium_label",
      btn_reset_password: "btn_reset_password",
    },
    address: {
      address_title: "address_title",
      address_table: {
        id_column: "address_table.id_column",
        street_name_column: "street_name_column",
        neighborhood_column: "neighborhood_column",
        zipcode_column: "zipcode_column",
        state_column: "state_column",
        capital_column: "capital_column",
        date_column: "date_column",
      },
      form: {
        create_address: "address.form.create_address",
        address_title: "address.form.address_title",
        address_edit_title: "address.form.address_edit_title",
        address_create_title: "address.form.address_create_title",
        btn_save_address: "address.form.btn_save_address",
        btn_edit_address: "address.form.btn_edit_address",
        address_id: "address.form.address_id",
        street_name_label: "address.form.street_name_label",
        number_label: "address.form.number_label",
        neighborhood_label: "address.form.neighborhood_label",
        complimentary_information_label: "address.form.complimentary_information_label",
        zipcode_label: "address.form.zipcode_label",
        state_label: "address.form.state_label",
        country_label: "address.form.country_label",
        city_label: "address.form.city_label",
        latitude_label: "address.form.latitude_label",
        longitude_label: "address.form.longitude_label",
      },
      modal: {
        title: "address.modal.title",
        description: "address.modal.description",
      },
    },
    contacts: {
      contact_tile: "contacts.contact_tile",
      contact_table: {
        contact_id_column: "contacts.contact_table.contact_id_column",
        phone_area_column: "contacts.contact_table.phone_area_column",
        phone_code_column: "contacts.contact_table.phone_code_column",
        phone_number_column: "contacts.contact_table.phone_number_column",
        talk_to_column: "contacts.contact_table.talk_to_column",
        email_column: "contacts.contact_table.email_column",
        isMain_column: "contacts.contact_table.isMain_column",
        createAt_column: "contacts.contact_table.createAt_column",
      },
      form: {
        contact_tile: "contacts.form.contact_tile",
        create_contact_title: "contacts.form.create_contact_title",
        contacts_edit_title: "contacts.form.contacts_edit_title",
        contacts_create_title: "contacts.form.contacts_create_title",
        contact_id: "contacts.form.contact_id",
        talk_to_label: "contacts.form.talk_to_label",
        phone_area_label: "contacts.form.phone_area_label",
        phone_code_label: "contacts.form.phone_code_label",
        phone_number_label: "contacts.form.phone_number_label",
        email_label: "contacts.form.email_label",
        main_contact_label: "contacts.form.main_contact_label",
        main_contact_description: "contacts.form.main_contact_description",
        btn_save_contacts: "contacts.form.btn_save_contacts",
        btn_edit_contacts: "contacts.form.btn_edit_contacts",
      },
      modal: {
        title: "contacts.modal.title",
        description: "contacts.modal.description",
      },
    },
    payments: {
      payment_title: "payment_title",
      payment_paid: "payment_paid",
      payment_pending: "payment_pending",
      payment_table: {
        payment_id_column: "payment_id_column",
        order_id_column: "order_id_column",
        created_date_column: "created_date_column",
        payment_date_column: "payment_date_column",
        payment_type_column: "payment_type_column",
        gross_value_column: "gross_value_column",
        net_value_column: "net_value_column",
        fee_column: "fee_column",
        status_column: "status_column",
        action_column: "action_column",
      },
      summary: {
        summary_title: "summary_title",
        amount_orders: "amount_orders",
        gross_value_total: "gross_value_total",
        net_value_total: "net_value_total",
        fee_total: "fee_total",
        last_order_date: "last_order_date",
      },
    },
    messages: {
      customer_edit_success: "messages.customer_edit_success",
      customer_edit_error: "messages.customer_edit_error",
      address_edit_success: "messages.address_edit_success",
      address_create_success: "messages.address_create_success",
      address_error: "messages.address_error",
      contacts_edit_success: "messages.contacts_edit_success",
      contacts_create_success: "messages.contacts_create_success",
      contacts_error: "messages.contacts_error",
    },
  },
  conciergeScreen: {
    main_title_concierge: "main.title.concierge",
    btn_create_concierge: "btn_create_concierge",
    tab_option_all_label: "tab_option_all_label",
    tab_option_inactive_label: "tab_option_inactive_label",
    tab_option_active_label: "tab_option_active_label",
    table_main: {
      header: {
        id: "concierge.id",
        name: "concierge.name",
        cpfDocument: "concierge.cpfDocument",
        rgDocument: "concierge.rgDocument",
        email: "concierge.email",
        phone: "concierge.phone",
        celphone: "concierge.celphone",
        isActive: "concirge.isActive",
      },
      filters: {
        sort_by_name_asc: "sort_by_name_asc",
        sort_by_name_desc: "sort_by_name_desc",
        search_concierge: "search_concierge",
      },
      row: {
        rowDocumentTitle: "row.documentTitle",
        rowContactTitle: "row.ContactTitle",
      },
    },
    form: {
      createForm: {
        create_title: "concierge_create_title",
        personalInformation: "concierge.personalInformation",
        contactIntomation: "concierge.contactInformation",
        conciergeNameLabel: "concierge.nameLabel",
        conciergeDocumentNumberLabel: "concierge.documentNumberLabel",
        conciergeIDLabel: "concierge.idLabel",
        conciergePhoneLabel: "concierge.phoneLabel",
        conciergeCellphoneLabel: "concierge.cellphoneLabel",
        conciergeEmailLabel: "concierge.emailLabel",
        conciergeGenderLabel: "concierge.genderLabel",
        btnCreateConcierge: "btn.createConcierge",
        isActiveLabel: "isActiveConciergeLabel",
        conciergeBreadcrumbsNavigationList: "conciergeBreadcrumbsNavigationList",
        conciergeBreadcrumbsNavigationEdit: "conciergeBreadcrumbsNavigationEdit",
      },
      editForm: {
        edit_title: "concierge_edit_title",
        btnEditConcierge: "btn.editConcierge",
      },
      sucess_message: "concierge.sucess_message",
      error_message: "concierge.error_message",
    },
  },
  condominiumScreen: {
    main_title: "main.title.condominium",
    btn_create_condominium: "btn_create_condominium",
    table_main: {
      header: {
        id: "condominium.id",
        name: "condominium.name",
        createdAt: "condominium.createdAt",
        cnpj: "condominium.cnpj",
        managerName: "condominium.managerName",
      },
      filters: {
        sort_by_name_asc_con: "sort_by_name_asc_con",
        sort_by_name_desc_con: "sort_by_name_desc_con",
        search_condominium: "search_condominium",
      },
    },
    form: {
      createForm: {
        notes_label: "condominium_notes_label",
        create_title: "condominium_create_title",
        condominiumInformation: "condominium.information",
        condominiumAddress: "condominiumAddress",
        condominiumNameLabel: "condominiumNameLabel",
        cnpjDocumentLabel: "condominium.cnpjDocumentLabel",
        managernameLabel: "condominium.managernameLabel",
        managerdocumentLabel: "condominium.managerdocumentLabel",
        managerdocumenttypeLabel: "condominium.managerdocumenttypeLabel",
        referralCode: "condominium.referralCode",
        address: {
          streetNameLabel: "condominium.streetNameLabel",
          numberLabel: "condominium.numberLabel",
          neighborhoodLabel: "condominium.neighborhoodLabel",
          complimentaryInformationLabel: "condominium.complimentaryInformationLabel",
          zipCodeLabel: "condominium.zipCodeLabel",
          cityLabel: "condominium.cityLabel",
          stateLabel: "condominium.stateLabel",
          countryLabel: "condominium.countryLabel",
        },
        btnCreateCondominium: "btn.createCondominium",
        btnCancelCondominium: "btn.cancel.condominium",
        condominiumBreadcrumbsNavigationList: "condominiumBreadcrumbsNavigationList",
        condominiumBreadcrumbsNavigationEdit: "condominiumBreadcrumbsNavigationEdit",
      },
      editForm: {
        edit_title: "condominium_edit_title",
        btnEditCondominium: "btn.editCondominium",
      },
      sucess_message: "condominium.sucess_message",
      error_message: "condominium.error_message",
    },
  },
  overviewScreen: {
    main_title: "main.title.overview",
    label_overview_period: "label_overview_time",
    customerSubscriptions: {
      amount_customers: "amount_customers",
      labelcustomerSubscriptions: "customerSubscriptions",
      labelThisYear: "labelThisYear",
      labelLastYear: "labelLastYear",
      year: "year",
      months: {
        jan: "Jan",
        feb: "Feb",
        mar: "Mar",
        apr: "Apr",
        may: "May",
        jun: "Jun",
        jul: "Jul",
        aug: "Aug",
        sep: "Sep",
        oct: "Oct",
        nov: "Nov",
        dec: "Dec",
      },
    },
    latestTransactions: {
      labelLatestTransactions: "labelLatestTransactions",
      labelLastWeek: "labelLastWeek",
    },
  },
  businessPartnerScreen: {
    main_title: "main.title.businessPartner",
    btn_create_businessPartner: "btn_create_businessPartner",
    table_main: {
      header: {
        id: "businessPartner.id",
        name: "businessPartner.name",
        homeService: "businessPartner.homeService",
        createdAt: "businessPartner.createdAt",
        cnpj: "businessPartner.cnpjDocument",
      },
      filters: {
        homeService: "filters.homeService",
        search_businessPartner: "search_businessPartner",
        sort_by_name_asc_bp: "sort_by_name_asc_bp",
        sort_by_name_desc_bp: "sort_by_name_desc_bp",
      },
    },
    form: {
      notes_label: "notes_label",
      createForm: {
        create_title: "businessPartner_create_title",
        businessPartnerInformation: "businessPartner.information",
        businessPartnerAddress: "businessPartnerAddress",
        businessPartnerContact: "businessPartnerContact",
        cnpjDocumentLabel: "businessPartner.cnpjDocumentLabel",
        businessPartnerNameLabel: "businessPartnerNameLabel",
        homeService: "businessPartner.homeService",
        address: {
          streetNameLabel: "businessPartner.streetNameLabel",
          numberLabel: "businessPartner.numberLabel",
          neighborhoodLabel: "businessPartner.neighborhoodLabel",
          complimentaryInformationLabel: "businessPartner.complimentaryInformationLabel",
          zipCodeLabel: "businessPartner.zipCodeLabel",
          cityLabel: "businessPartner.cityLabel",
          stateLabel: "businessPartner.stateLabel",
          countryLabel: "businessPartner.countryLabel",
        },
        contact: {
          phoneNumber: "businessPartner.phoneNumberLabel",
          talkTo: "businessPartner.talkToLabel",
          email: "businessPartner.emailLabel",
        },
        bpBreadcrumbsNavigationList: "bpBreadcrumbsNavigationList",
        bpBreadcrumbsNavigationEdit: "bpBreadcrumbsNavigationEdit",
        btnCreateBusinessPartner: "btn.createBusinessPartner",
        btnCancelBusinessPartner: "btn.cancel.businessPartner",
      },
      editForm: {
        edit_title: "businessPartner_edit_title",
        btnEditBusinessPartner: "btn.editBusinessPartner",
      },
      sucess_message: "businessPartner.sucess_message",
      error_message: "businessPartner.error_message",
    },
  },
  businessPartnerServiceScreen: {
    main_title: "main.title.businessPartnerServiceScreen",
    btn_create_businessPartnerServiceScreen: "btn_create_businessPartnerService",
    businessPartnerServiceToolTip: "businessPartnerServiceToolTip",
    table_main: {
      header: {
        id: "businessPartner.service.id",
        service: "businessPartner.service",
        description: "businessPartner.service.description",
        takenRate: "businessPartner.service.takenRate",
        createdAt: "businessPartner.service.createdAt",
      },
    },
    form: {
      createForm: {
        serviceInformation: "businessPartner.information",
        create_title: "businessPartnerService_create_title",
        service: "businessPartner.service",
        description: "businessPartner.description",
        takenRate: "businessPartner.takenRate",
        createdAt: "businessPartner.createdAt",
        updatedAt: "businessPartner.updatedAt",
        bpsBreadcrumbsNavigationList: "bpsBreadcrumbsNavigationList",
        bpsBreadcrumbsNavigationEdit: "bpsBreadcrumbsNavigationEdit",
        btnCreateBusinessPartnerService: "btn.createBusinessPartnerService",
        btnCancelBusinessPartnerService: "btn.cancelBusinessPartnerService",
      },
      editForm: {
        edit_title: "businessPartnerService_edit_title",
        btnEditBusinessPartnerService: "btn.editBusinessPartnerService",
      },
      sucess_message: "businessPartnerService.sucess_message",
      error_message: "businessPartnerService.error_message",
    },
  },
  paymentReportScreen: {
    main_title: "main.title.paymentReportScreen",
    btn_filter_paymentReportScreen: "btn_filter_businessPartnerServiceScreen",
    btn_closePdf_paymentReportScreen: "btn_closePdf_paymentReportScreen",
    btn_preview_paymentReportScreen: "btn_preview_paymentReportScreen",
    btn_download_paymentReportScreen: "btn_download_paymentReportScreen",
    pdf_name: "pdf_name",
    placeHolder_filter_orderId_paymentReportScreen:
      "placeHolder_filter_orderId_businessPartnerServiceScreen",
    label_filter_orderDateFrom_paymentReportScreen:
      "label_filter_orderDateFrom_paymentReportScreen",
    label_filter_orderDateTo_paymentReportScreen: "label_filter_orderDateTo_paymentReportScreen",
    label_filter_fromCustomer_paymentReportScreen: "label_filter_fromCustomer_paymentReportScreen",
    label_filter_PaidOnly_paymentReportScreen: "label_filter_PaidOnly_paymentReportScreen",
    label_filter_GrooupByStatus_paymentReportScreen:
      "label_filter_GrooupByStatus_paymentReportScreen",
    label_summary_from_paymentReportScreen: "label_summary_from_paymentReportScreen",
    label_summary_payments_paymentReportScreen: "label_summary_payments_paymentReportScreen",
    details: {
      orderDate: "orderDate",
      paymentDate: "paymentDate",
      fatura: "fatura",
      orderNumber: "orderNumber",
      paidBy: "payedFor",
      orderType: "orderType",
      assistedBy: "assistedBy",
      description: "description",
      qty: "qty",
      price: "price",
      total: "total",
      subTotal: "subTotal",
      takenRate: "takenRate",
      paymentType: {
        type: "type",
      },
    },
    table_main: {
      header: {
        orderDate: "paymentReport.orderDate",
        paymentDate: "paymentReport.paymentDate",
      },
    },
    status: {
      pending: "status.pending",
      paid: "status.paid",
      canceled: "status.canceled",
      refund: "status.refund",
    },
  },
  eventScreen: {
    main_title: "main.title.event",
    btn_create_event: "btn_create_event",
    eventCustomersToolTip: "eventCustomersToolTip",
    tab_option_free_label: "tab_option_free_label",
    tab_option_paid_label: "tab_option_paid_label",
    table_main: {
      header: {
        id: "event.id",
        shortEventDescription: "event.shortEventDescription",
        eventDate: "event.eventDate",
        eventValue: "event.eventValue",
        isFree: "event.isFree",
        maximumCustomers: "maximumCustomers",
        amountOfCustomer: "amountOfCustomer",
        createdAt: "event.createdAt",
      },
      filters: {
        sort_by_name_asc_ev: "sort_by_name_asc_ev",
        sort_by_name_desc_ev: "sort_by_name_desc_ev",
      },
      // row: {
      //   rowDocumentTitle: "row.documentTitle",
      //   rowContactTitle: "row.ContactTitle",
      // },
    },
    form: {
      createForm: {
        create_title: "event_create_title",
        eventInformation: "event.information",
        eventAddress: "eventAddress",
        shortEventDescriptionLabel: "shortEventDescriptionLabel",
        fullEventDescriptionLabel: "fullEventDescriptionLabel",
        eventDateLabel: "eventDateLabel",
        eventValueLabel: "event.eventValueLabel",
        isFreeLabel: "event.isFreeLabel",
        maximumCustomers: "event.maximumCustomers",
        address: {
          streetNameLabel: "event.streetNameLabel",
          numberLabel: "event.numberLabel",
          neighborhoodLabel: "event.neighborhoodLabel",
          complimentaryInformationLabel: "event.complimentaryInformationLabel",
          zipCodeLabel: "event.zipCodeLabel",
          cityLabel: "event.cityLabel",
          stateLabel: "event.stateLabel",
          countryLabel: "event.countryLabel",
        },
        btnCreateEvent: "btn.createEvent",
        btnCancelEvent: "btn.cancel.event",
        eventBreadcrumbsNavigationList: "eventBreadcrumbsNavigationList",
        eventBreadcrumbsNavigationEdit: "eventBreadcrumbsNavigationEdit",
      },
      editForm: {
        edit_title: "event_edit_title",
        btnEditEvent: "btn.editEvent",
      },
      filters: {
        isFreeLabel: "isFreeLabel",
        searchByEvent: "searchByEvent",
      },
      sucess_message: "event.sucess_message",
      error_message: "event.error_message",
    },
  },
  customerEventScreen: {
    main_title: "main.title.customerEventScreen",
    cvBreadcrumbsNavigationList: "cvBreadcrumbsNavigationList",
    cvBreadcrumbsNavigationEdit: "cvBreadcrumbsNavigationEdit",
    table_main: {
      header: {
        name: "event.customer.name",
        createdAt: "event.customer.createdAt",
      },
    },
  },
  notification: {
    title: "notification.title",
    locale: "notification.locale",
    dashboard: {
      title: "notification.dashboard.title",
      not_found: "notification.dashboard.not_found",
    },
  },
}
